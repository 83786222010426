import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ProjectContext } from './ProjectContext';
import { useRouter } from '../../hooks';
import { excludeRequests } from '../utils/excludeRequests';

export function ProjectProvider({ children }) {
  const [project, setProject] = useState(null);
  const ROUTER = useRouter();

  if (excludeRequests()) {
    return;
  }

  const { isPending, error, data } = useQuery({
    queryKey: ['fetchProject'],
    queryFn: () => {
      if (ROUTER.IDS.PROJECT_UNIQUE_ID) {
        fetch(ROUTER.API.PROJECTS_SHOW, {
          headers: {
            'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
              ?.content,
          },
        }).then((res) => res.json());
      }
    },
  });

  const queryClient = useQueryClient();

  if (data && !isPending && data !== project) {
    setProject(data);
  }

  const refetchProject = () => {
    queryClient.refetchQueries({
      queryKey: ['fetchProject'],
    });
  };

  return (
    <ProjectContext.Provider
      value={{
        project,
        refetchProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
