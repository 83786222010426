import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { AccountContext } from './AccountContext';
import { useRouter } from '../../hooks';

export function AccountProvider({ children }) {
  const ROUTER = useRouter();

  const { data } = useQuery({
    queryKey: ['fetchAccount'],
    queryFn: () =>
      fetch(ROUTER.API.ACCOUNT, {
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
        },
      }).then((res) => res.json()),
  });

  return (
    <AccountContext.Provider value={data?.payload}>
      {children}
    </AccountContext.Provider>
  );
}
