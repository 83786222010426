import React from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Label,
} from '../../../../../ui';

import { useRequest, useRouter } from '../../../../../hooks';

import styles from './NewChecklist.module.css';

export function NewChecklistModal({ open, onOpenChange }) {
  const { request } = useRequest();
  const ROUTER = useRouter();

  const { register, handleSubmit, reset, watch } = useForm();

  const queryClient = useQueryClient();

  const createChecklistGroup = useMutation({
    mutationFn: (data: { name: string }) => {
      return fetch(ROUTER.API.CHECKLISTS_CREATE, {
        method: 'post',
        body: JSON.stringify({
          name: data.name,
        }),
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchChecklists'],
      });
      reset();
      onOpenChange();
    },
    onError: () => {},
  });

  const onSubmit = (data) => {
    createChecklistGroup.mutate(data);
  };

  const name = watch('name');

  return (
    <Dialog open={open} onOpenChange={onOpenChange} size='sm'>
      <DialogHeader>
        <h1>Create a new checklist</h1>
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody className={clsx(styles.base)}>
          <Label label='Name' name='name'>
            <Input register={register} name='name' required />
          </Label>
        </DialogBody>
        <DialogFooter>
          <Button
            type='submit'
            label='Create'
            disabled={!(name && name.trim())}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
