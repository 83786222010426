import React from 'react';
import clsx from 'clsx';

import { Attachment, Card, CardRow, SectionHeader } from '../../../../../ui';
import { Comments } from '../../../../../features/Comments/Comments';

import { toLongDate } from '../../../../../utils/dates/toLongDate';
import { useRouter } from '../../../../../hooks';

import styles from '../../TasksShow.module.css';

export function ReadTask({ task }) {
  const ROUTER = useRouter();

  return (
    <>
      <Card className={clsx(styles.card)}>
        <CardRow className={clsx(styles['card-row'])}>
          <div className={clsx(styles.user)}>
            <div
              className={clsx(styles.avatar)}
              style={{ backgroundImage: `url(${task?.user?.avatar_url})` }}
            />
            <span>{task?.user?.name}</span>
          </div>
        </CardRow>
        <CardRow className={clsx(styles['card-row'])}>
          <SectionHeader text='Due at' />
          <span>{toLongDate({ date: task.due_at })}</span>
        </CardRow>
        <CardRow className={clsx(styles['card-row'])}>
          <SectionHeader text='Description' />
          {<div dangerouslySetInnerHTML={{ __html: task.description }} />}

          {task?.attachments?.length > 0 && (
            <>
              <SectionHeader text='Attachments' />
              <Card>
                {task?.attachments?.map((attachment) => (
                  <Attachment
                    key={attachment.url}
                    filename={attachment.original_filename}
                    size={attachment.size}
                    url={attachment.url}
                  />
                ))}
              </Card>
            </>
          )}
        </CardRow>
      </Card>
      <Comments parent={{ parent_type: 'Task', parent_id: task?.unique_id }} />
    </>
  );
}
