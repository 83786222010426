import React from 'react';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';

import { PageLayout, Header, Card, CardRow, EmptyState } from '../../ui';

import { useRouter } from '../../hooks';

import { generatePolymorphicUrl } from '../../utils/urls/generatePolymorphicUrl';

import styles from './Inbox.module.css';

dayjs.extend(relativeTime);

export function Inbox() {
  const ROUTER = useRouter();

  const { data, isPending } = useQuery({
    queryKey: ['fetchNotifications'],
    queryFn: () =>
      fetch(ROUTER.API.NOTIFICATIONS, {
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
        },
      }).then((res) => res.json()),
  });

  return (
    <PageLayout>
      <Header>
        <h1>Inbox</h1>
      </Header>
      {!isPending && data?.notifications.length > 0 && (
        <Card>
          {data?.notifications.map((notification) => (
            <CardRow
              className={clsx(
                styles.notification,
                'flex justify-between !p-0',
                notification.is_read && styles.read
              )}
            >
              <a
                className='flex w-full p-4'
                href={generatePolymorphicUrl({
                  type: notification.notificationable_type,
                  uniqueId: notification.notificationable.unique_id,
                  organizationSlug: ROUTER.PARAMS.ORGANIZATION_SLUG,
                  projectUniqueId:
                    notification.notificationable.project.unique_id,
                })}
              >
                <div className='flex justify-between w-full'>
                  <div className='flex flex-col align-center'>
                    <span className='flex items-center'>
                      {!notification.is_read && (
                        <div
                          className={clsx(
                            styles['unread-message'],
                            'mr-2 rounded-full'
                          )}
                        />
                      )}
                      <strong>
                        {notification.notificationable?.title ||
                          notification.notificationable?.name ||
                          notification.notificationable?.custom_name}{' '}
                      </strong>
                    </span>
                    <span className={styles['project-name']}>
                      {notification.notificationable?.project?.name}
                    </span>
                  </div>
                  <div className={clsx('flex items-center', styles.date)}>
                    {dayjs(notification.updated_at).fromNow()}
                  </div>
                </div>
              </a>
            </CardRow>
          ))}
        </Card>
      )}

      {!isPending && data?.notifications.length === 0 && (
        <EmptyState>
          <h2>You don't have any notifications yet</h2>
        </EmptyState>
      )}
    </PageLayout>
  );
}
