import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { App } from '../app';
import { AppsIndex } from '../views/apps/index/index';
import { AccountIndex } from '../views/account/index/index';
import { Billing } from '../views/billing';
import { CalendarIndex } from '../views/calendar/index/index';
import { ChecklistsIndex } from '../views/checklists/index/index';
import { EventsEdit } from '../views/events/edit';
import { EventsShow } from '../views/events/show';
import { FilesIndex } from '../views/files/index/index';
import { FilesNew } from '../views/files/new';
import { FilesShow } from '../views/files/show';
import { Onboarding } from '../views/onboarding';
import { People } from '../views/people/index';
import { PostCategoriesIndex } from '../views/post-categories/index/index';
import { PostsCreate } from '../views/posts/create';
import { PostsIndex } from '../views/posts/index/index';
import { PostsShow } from '../views/posts/show';
import { ProjectsIndex } from '../views/projects/index/index';
import { ProjectsShow } from '../views/projects/show';
import { Settings } from '../views/settings/index';
// import { TasksNew } from './src/views/tasks/new';
import { TasksShow } from '../views/tasks/show';
import { OauthSlack } from '../views/oauth/slack';
import { ProjectSettings } from '../views/projects/settings/ProjectsSettings';
import { Activity } from '../views/activity';
import { Help } from '../views/help';
import { Inbox } from '../views/inbox';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/onboarding',
        element: <Onboarding />,
      },
      {
        path: '/:organizationSlug/help',
        element: <Help />,
      },

      {
        path: '/apps/oauth/slack',
        element: <OauthSlack />,
      },
      {
        path: '/:organizationSlug/projects',
        element: <ProjectsIndex />,
      },
      {
        path: '/:organizationSlug/inbox',
        element: <Inbox />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId',
        element: <ProjectsShow />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/settings',
        element: <ProjectSettings />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/checklists',
        element: <ChecklistsIndex />,
      },

      {
        path: '/:organizationSlug/settings',
        element: <Settings />,
      },
      {
        path: '/:organizationSlug/settings/apps',
        element: <AppsIndex />,
      },
      {
        path: '/:organizationSlug/people',
        element: <People />,
      },
      {
        path: '/:organizationSlug/settings/account',
        element: <AccountIndex />,
      },
      {
        path: '/:organizationSlug/settings/billing',
        element: <Billing />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/tasks/:taskUniqueId',
        element: <TasksShow />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/calendar',
        element: <CalendarIndex />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/events/:eventUniqueId',
        element: <EventsShow />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/events/:eventUniqueId/edit',
        element: <EventsEdit />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/posts',
        element: <PostsIndex />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/posts/categories/:postCategoryUniqueId',
        element: <PostCategoriesIndex />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/posts/categories/:postCategoryUniqueId/new',
        element: <PostsCreate />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/posts/:postUniqueId',
        element: <PostsShow />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/files',
        element: <FilesIndex />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/files/new',
        element: <FilesNew />,
      },
      {
        path: '/:organizationSlug/projects/:projectUniqueId/files/:fileUniqueId',
        element: <FilesShow />,
      },
      {
        path: '/:organizationSlug/activity',
        element: <Activity />,
      },
    ],
  },
]);
