import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '../../../../../ui';

import { useRequest } from '../../../../../hooks/useRequest';

export function DeleteChecklistModal({ open, onOpenChange, checklistId }) {
  const queryClient = useQueryClient();

  const deleteChecklist = useMutation({
    mutationFn: (checklistId) => {
      fetch(`/api/v1/checklists/${checklistId}`, {
        method: 'delete',
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: ['fetchChecklists'],
        });
        onOpenChange();
      }, 100);
    },
  });

  return (
    <Dialog open={open} onOpenChange={onOpenChange} size='sm'>
      <DialogHeader>
        <h1>Delete checklist</h1>
      </DialogHeader>
      <DialogBody>
        <p>
          Are you sure you want to delete this checklist? Deleting a checklist
          will delete all related tasks and files. This can't be undone.
        </p>
      </DialogBody>
      <DialogFooter>
        <Button
          variant='secondary'
          label='Cancel'
          onClick={() => onOpenChange()}
        />
        <Button
          variant='danger'
          label='Yes, delete'
          onClick={() => deleteChecklist.mutate(checklistId)}
        />
      </DialogFooter>
    </Dialog>
  );
}
