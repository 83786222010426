import React from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useRouter } from '../../hooks';
import { Button } from '../../ui';

import { NotificationOffIcon } from './components/icons/NotificationOff';
import { NotificationOnIcon } from './components/icons/NotificationOn/NotificationOn';

export function Subscription({ parent }) {
  if (!parent?.parent_type || !parent?.parent_id) {
    return null;
  }

  const queryClient = useQueryClient();

  const ROUTER = useRouter();

  const { isPending, error, data } = useQuery({
    queryKey: ['fetchSubscriptionStatus'],
    queryFn: () => {
      return fetch(
        `${ROUTER.API.SUBSCRIPTIONS}?parent_type=${parent.parent_type}&parent_id=${parent.parent_id}`,
        {
          headers: {
            'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
              ?.content,
          },
        }
      ).then((res) => res.json());
    },
  });

  const createSubscription = useMutation({
    mutationFn: () => {
      return fetch(ROUTER.API.SUBSCRIPTIONS, {
        method: 'post',
        body: JSON.stringify({
          parent_type: parent.parent_type,
          parent_id: parent.parent_id,
        }),
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchSubscriptionStatus'],
      });
    },
  });

  const removeSubscription = useMutation({
    mutationFn: () => {
      return fetch(ROUTER.API.SUBSCRIPTIONS, {
        method: 'delete',
        body: JSON.stringify({
          parent_type: parent.parent_type,
          parent_id: parent.parent_id,
        }),
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchSubscriptionStatus'],
      });
    },
  });

  const isSubscribed = !isPending && data?.is_subscribed;

  return (
    <Button
      label={isSubscribed ? 'Stop Notifying Me' : 'Notify Me'}
      variant='secondary'
      onClick={() => {
        isSubscribed
          ? removeSubscription.mutate()
          : createSubscription.mutate();
      }}
    >
      {isSubscribed ? <NotificationOffIcon /> : <NotificationOnIcon />}
    </Button>
  );
}
