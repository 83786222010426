import React, { useEffect } from 'react';
import clsx from 'clsx';

import {
  Attachment,
  CalendarPreview,
  Card,
  CardRow,
  Header,
  Label,
  PageLayout,
  PencilIcon,
  Subheader,
  TrashIcon,
} from '../../../ui';
import { Comments } from '../../../features/Comments/Comments';

import { useRequest, useRouter } from '../../../hooks';
import { toLongDate } from '../../../utils/dates/toLongDate';

import styles from './EventsShow.module.css';
import { Dropdown } from '../../../ui/components/DropdownMenu/DropdownMenu';

export function EventsShow() {
  const { data, request } = useRequest();
  const ROUTER = useRouter();

  const fetchEvent = () => {
    request({
      url: ROUTER.API.EVENTS_SHOW,
      onSuccess: () => {},
      onError: () => {},
    });
  };

  const deleteEvent = () => {
    request({
      method: 'delete',
      url: ROUTER.API.EVENTS_DELETE,
      onSuccess: () => {
        ROUTER.NAVIGATE(ROUTER.PATH.CALENDAR_INDEX);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  return (
    <PageLayout>
      <Header>
        <h1>{data?.title}</h1>
        <Dropdown
          items={[
            {
              text: 'Edit',
              icon: <PencilIcon />,
              onClick: () => {
                ROUTER.NAVIGATE(ROUTER.PATH.EVENT_EDIT);
              },
            },
            {
              text: 'Delete',
              icon: <TrashIcon />,
              onClick: () => {
                deleteEvent();
              },
              danger: true,
            },
          ]}
        />
      </Header>
      <Card className={clsx(styles.card)}>
        <CardRow className={clsx(styles['card-row'], styles.dates)}>
          <div className={clsx(styles['calendar-wrapper'])}>
            <CalendarPreview date={data?.start_at} />
          </div>
          <div>
            <Label label='Starts'></Label>{' '}
            <span>
              <strong>{toLongDate({ date: data?.start_at }) || ''}</strong>
            </span>
          </div>
        </CardRow>
        {data?.end_at && (
          <CardRow className={clsx(styles['card-row'], styles.dates)}>
            <div className={clsx(styles['calendar-wrapper'])}>
              <CalendarPreview date={data?.end_at} />
            </div>
            <div>
              <Label label='Ends'></Label>
              <span>
                <strong>{toLongDate({ date: data?.end_at }) || ''}</strong>
              </span>
            </div>
          </CardRow>
        )}
        <CardRow className={clsx(styles['card-row'])}>
          <Label label='Notes'></Label>
          <div dangerouslySetInnerHTML={{ __html: data?.notes || '' }} />
          {data?.attachments.length > 0 && (
            <div className={clsx(styles['attachments-wrapper'])}>
              <Subheader>Attachments</Subheader>
              <Card className={clsx(styles['card'])}>
                {data?.attachments.map((attachment) => (
                  <CardRow
                    key={attachment.url}
                    className={clsx(styles['attachment-row'])}
                  >
                    <Attachment
                      key={attachment.url}
                      filename={attachment.original_filename}
                      size={attachment.size}
                      url={attachment.url}
                    />
                  </CardRow>
                ))}
              </Card>
            </div>
          )}
        </CardRow>
      </Card>

      <Comments
        parent={{
          parent_type: 'Event',
          parent_id: data?.unique_id,
        }}
      />
    </PageLayout>
  );
}
