import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import * as Checkbox from '@radix-ui/react-checkbox';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useRequest, useRouter } from '../../../../../hooks/';

import {
  Card,
  CardRow,
  DropdownMenu,
  TrashIcon,
  PlusCircleIcon,
  Input,
} from '../../../../../ui';

import styles from './ChecklistGroup.module.css';

export function ChecklistGroup({
  setShowDeleteChecklistModal,
  setChecklistIdToDelete,
  checklist,
}) {
  const [isAddingTask, setIsAddingTask] = useState(false);

  const { register, reset } = useForm();

  const ROUTER = useRouter();

  const { request } = useRequest();
  const queryClient = useQueryClient();

  const createTask = useMutation({
    mutationFn: (newTask: { checklist_unique_id: string; name: string }) => {
      return fetch(ROUTER.API.TASKS_BASE, {
        method: 'post',
        body: JSON.stringify(newTask),
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchChecklists'],
      });
      reset({ name: '' });
      setIsAddingTask(false);
    },
  });

  const deleteTask = useMutation({
    mutationFn: (taskUniqueId) => {
      return fetch(`${ROUTER.API.TASKS_BASE}/${taskUniqueId}`, {
        method: 'delete',
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchChecklists'],
      });
    },
  });

  const updateTask = useMutation({
    mutationFn: ({ task, completed }) => {
      return fetch(`${ROUTER.API.TASK_STATUS}/${task.unique_id}`, {
        method: 'PUT',
        body: JSON.stringify({ completed }),
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchChecklists'],
      });
    },
  });

  const items = [
    {
      text: 'Delete checklist',
      icon: <TrashIcon />,
      onClick: () => {
        setShowDeleteChecklistModal(true);
        setChecklistIdToDelete(checklist.unique_id);
      },
      danger: true,
    },
  ];

  return (
    <Card className={clsx(styles.base)}>
      <CardRow className={clsx(styles['card-row'])}>
        <span className={clsx(styles['group-name'])}>{checklist.name}</span>
        <DropdownMenu items={items} />
      </CardRow>
      {checklist.tasks.map((task) => (
        <CardRow className={clsx(styles['card-row'])} key={task.unique_id}>
          <div className={clsx(styles['status-name'])}>
            <Checkbox.Root
              className={clsx(styles['checkbox-root'])}
              checked={task?.completed}
              onCheckedChange={(completed) => {
                updateTask.mutate({ task, completed });
              }}
              id='c1'
            >
              <Checkbox.Indicator
                className={clsx(styles['checkbox-indicator'])}
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.5 12.5L10.0168 17.7247L10.4177 17.0238C12.5668 13.2658 15.541 10.0448 19.1161 7.60354L20 7'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </Checkbox.Indicator>
            </Checkbox.Root>
            <a href={`${ROUTER.PATH.TASK_BASE}/tasks/${task.unique_id}`}>
              <span>{task.name}</span>
            </a>
          </div>
          <DropdownMenu
            items={[
              {
                text: 'Delete',
                icon: <TrashIcon />,
                onClick: () => {
                  deleteTask.mutate(task.unique_id);
                },
                danger: true,
              },
            ]}
          />
        </CardRow>
      ))}
      {isAddingTask && (
        <CardRow className={clsx(styles['card-row'])}>
          <Input
            placeholder='Add a name and press return to create'
            register={register}
            name='name'
            onKeyDown={(e) => {
              // enter/return pressed
              if (e.keyCode == '13') {
                createTask.mutate({
                  checklist_unique_id: checklist.unique_id,
                  name: e.target.value,
                });
              }
            }}
            onBlur={(e) => {
              if (e.target.value.trim() !== '') {
                createTask.mutate({
                  checklist_unique_id: checklist.unique_id,
                  name: e.target.value,
                });
              }
            }}
            required
          />
        </CardRow>
      )}
      {!isAddingTask && (
        <CardRow className={clsx(styles['card-row'], styles['add-task'])}>
          <button
            className={clsx(styles.button)}
            onClick={(e) => {
              e.preventDefault;
              setIsAddingTask(true);
            }}
          >
            <PlusCircleIcon />
            <span>Add a task</span>
          </button>
        </CardRow>
      )}
    </Card>
  );
}
