import React from 'react';
import clsx from 'clsx';
import { Outlet, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Toast from '@radix-ui/react-toast';

import { AccountProvider } from '../contexts/account';
import { ProjectProvider } from '../contexts/project';

import { Nav } from './components/Nav';
import { Router } from '../router';
import { Permissions } from '../router/permissions';

import styles from './App.module.css';

export function App() {
  const queryClient = new QueryClient();
  const location = useLocation();
  const hideNavOnPaths = ['/', '/onboarding', '/apps/oauth/slack'];
  const showNav = !hideNavOnPaths.includes(location.pathname);

  return (
    <QueryClientProvider client={queryClient}>
      <AccountProvider>
        <ProjectProvider>
          <Router>
            <Permissions>
              <Toast.Provider>
                <div className={clsx(styles.wrapper)}>
                  {showNav && <Nav />}
                  <Outlet />
                </div>
              </Toast.Provider>
            </Permissions>
          </Router>
        </ProjectProvider>
      </AccountProvider>
    </QueryClientProvider>
  );
}
