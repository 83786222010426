import React, { useState } from 'react';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';

import { Header, Button, PageLayout, EmptyState } from '../../../ui';
import { NewChecklistModal } from './components/NewChecklistModal';
import { DeleteChecklistModal } from './components/DeleteChecklistModal';
import { ChecklistGroup } from './components/ChecklistGroup';

import { useRouter } from '../../../hooks';

import styles from './ChecklistsIndex.module.css';

export function ChecklistsIndex() {
  const [showNewChecklistModal, setShowNewChecklistModal] = useState(false);
  const [showDeleteChecklistModal, setShowDeleteChecklistModal] =
    useState(false);
  const [checklistIdToDelete, setChecklistIdToDelete] = useState(null);

  const ROUTER = useRouter();

  const { isPending, error, data } = useQuery({
    queryKey: ['fetchChecklists'],
    queryFn: () =>
      fetch(ROUTER.API.CHECKLISTS_INDEX, {
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
        },
      }).then((res) => res.json()),
  });

  const hasNoChecklists = !isPending && !data?.payload?.length;

  return (
    <PageLayout>
      <Header>
        <h1>Checklists</h1>
        {data?.payload?.length ? (
          <Button
            label='New Checklist'
            onClick={() => setShowNewChecklistModal(true)}
          />
        ) : null}
      </Header>
      {hasNoChecklists ? (
        <EmptyState>
          <h2>You don't have any checklists yet</h2>
          <Button
            variant='secondary'
            label='Create a checklist'
            onClick={() => setShowNewChecklistModal(true)}
          />
        </EmptyState>
      ) : null}
      <div>
        {data?.payload?.length > 0 &&
          data.payload.map((checklist) => {
            return (
              <ChecklistGroup
                key={checklist.unique_id}
                setShowDeleteChecklistModal={setShowDeleteChecklistModal}
                setChecklistIdToDelete={setChecklistIdToDelete}
                checklist={checklist}
              />
            );
          })}
      </div>
      <div className={clsx(styles.grid)}></div>
      <NewChecklistModal
        open={showNewChecklistModal}
        onOpenChange={setShowNewChecklistModal}
      />
      <DeleteChecklistModal
        open={showDeleteChecklistModal}
        onOpenChange={() => {
          setShowDeleteChecklistModal(false);
          setChecklistIdToDelete(null);
        }}
        checklistId={checklistIdToDelete}
      />
    </PageLayout>
  );
}
