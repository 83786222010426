import React from 'react';

export function NotificationOffIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.46627 17.5337L3 21M6.46627 17.5337L17.9141 6.08593M6.46627 17.5337C6.11928 17.5013 5.77256 17.4659 5.42604 17.4274C4.42166 17.3158 3.77569 16.3065 4.09526 15.3478C4.25729 14.8617 4.42046 14.3836 4.46337 13.8687L4.81837 9.60867C5.12965 5.87324 8.25229 3 12.0007 3C14.4355 3 16.6063 4.21238 17.9141 6.08593M21 3L17.9141 6.08593M19.1986 9.79616L19.5382 13.8711C19.5811 14.3856 19.7441 14.8637 19.9054 15.3497C20.2236 16.3078 19.5779 17.3156 18.5745 17.4271C17.3333 17.5651 16.0887 17.6639 14.8428 17.7235C13.6321 17.7814 12.4202 17.8023 11.2086 17.7862L9.49476 19.5C9.97396 20.3929 10.9164 21 12.0006 21C13.5703 21 14.8428 19.7275 14.8428 18.1579V17.8125'
        stroke='#111111'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
