import React from 'react';
import * as Toast from '@radix-ui/react-toast';

import styles from './Toast.module.css';

export function ToastNotification({ open, title, description, icon }) {
  return (
    <>
      <Toast.Root
        className={styles.ToastRoot}
        open={open}
        onOpenChange={() => {}}
      >
        <div className={styles['content-wrapper']}>
          <div className='mr-2'>{icon}</div>
          <div>
            <Toast.Title className={styles.ToastTitle}>{title}</Toast.Title>
            <Toast.Description asChild>{description}</Toast.Description>
          </div>
        </div>
      </Toast.Root>
      <Toast.Viewport className={styles.ToastViewport} />
    </>
  );
}
