import { useRouter } from '../../hooks';

export function generatePolymorphicUrl({
  type,
  projectUniqueId,
  uniqueId,
  organizationSlug,
}) {
  switch (type) {
    case 'Event':
      return `/${organizationSlug}/projects/${projectUniqueId}/events/${uniqueId}`;
    case 'Task':
      return `/${organizationSlug}/projects/${projectUniqueId}/tasks/${uniqueId}`;
    case 'Post':
      return `/${organizationSlug}/projects/${projectUniqueId}/posts/${uniqueId}`;
    case 'ProjectFile':
      return `/${organizationSlug}/projects/${projectUniqueId}/files/${uniqueId}`;
    default:
      return '/';
  }
}
