import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ToastNotification } from './../../../ui/components/Toast/Toast';

import { AccountContext } from '../../../contexts/account';

import {
  Button,
  Card,
  CardRow,
  Header,
  PageLayout,
  Label,
  Input,
} from '../../../ui';

import { useRequest, useRouter } from '../../../hooks';

import styles from './AccountIndex.module.css';
import { setTime } from 'react-datepicker/dist/date_utils';

export function AccountIndex() {
  const [showAccountUpdateSuccessToast, setShowAccountUpdateSuccessToast] =
    useState(false);
  const [showAvatarSuccessToast, setShowAvatarSuccessToast] = useState(false);

  const { data: avatarData } = useRequest();

  const { isLoading } = useRequest();
  const ROUTER = useRouter();

  const account = useContext(AccountContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const updateAccount = useMutation({
    mutationFn: (data) => {
      return fetch(ROUTER.API.ACCOUNT, {
        method: 'put',
        body: JSON.stringify(data),
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchAccount'],
      });
      setShowAccountUpdateSuccessToast(true);
      setTimeout(() => {
        setShowAccountUpdateSuccessToast(false);
      }, 4000);
    },
  });

  const onSubmit = (data) => {
    updateAccount.mutate(data);
  };

  const queryClient = useQueryClient();

  const uploadAvatar = useMutation({
    mutationFn: (data) => {
      return fetch(
        `/api/v1/uploads-avatars?user_unique_id=${account?.unique_id}`,
        {
          method: 'post',
          body: data,
          headers: {
            'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
              ?.content,
            // 'Content-Type': 'application/json',
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchAccount'],
      });
      setShowAvatarSuccessToast(true);
      setTimeout(() => {
        setShowAvatarSuccessToast(false);
      }, 4000);
    },
  });

  return (
    <PageLayout>
      <Header>
        <h1>Account</h1>
      </Header>
      <Card className={clsx(styles.card)}>
        <CardRow className={clsx(styles['card-row'])}>
          <input
            id='avatar'
            type='file'
            name='name'
            style={{ display: 'none' }}
            onChange={(event) => {
              const file = event.currentTarget.files[0];
              const formData = new FormData();
              formData.append('file', file);
              uploadAvatar.mutate(formData);
            }}
          />
          <button
            className={clsx(styles['avatar-button'])}
            onClick={() => {
              document.getElementById('avatar')?.click();
            }}
          >
            <div
              style={{
                backgroundImage: `url(${
                  avatarData?.avatar_url || account?.avatar_url
                })`,
              }}
              className={clsx(styles.avatar)}
            />
            <div className={clsx(styles['upload-icon'])}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.6157 7.11581L18.5014 5.22177C19.0315 4.68941 19.1632 3.86631 18.7594 3.21582C18.2713 2.42948 17.6037 1.75331 16.8267 1.25131C16.204 0.848959 15.3862 0.937079 14.8626 1.46297L12.917 3.41714M16.6157 7.11581L5.52811 18.2521C5.25274 18.5287 5.11505 18.667 4.95435 18.7658C4.81188 18.8534 4.65654 18.9178 4.49406 18.9568C4.31079 19.0008 4.11608 19.0005 3.72665 18.9999L1 18.9955L1.06616 16.3151C1.07546 15.9381 1.08011 15.7497 1.12568 15.5726C1.16608 15.4156 1.23007 15.2658 1.31544 15.1282C1.41171 14.973 1.54444 14.8396 1.8099 14.573L12.917 3.41714M16.6157 7.11581L12.917 3.41714'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </button>
        </CardRow>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardRow className={clsx(styles['card-row'])}>
            <Label label='email'></Label>
            <p>{account?.email}</p>
          </CardRow>

          <CardRow className={clsx(styles['card-row'])}>
            <Label label='Name' name='name'>
              <Input
                register={register}
                name='name'
                defaultValue={account?.name}
              />
            </Label>
          </CardRow>
          <CardRow className={clsx(styles['card-footer'])}>
            <Button
              label='Reset'
              variant='secondary'
              onClick={(e) => {
                e.preventDefault();
                reset();
              }}
            />
            <Button
              label={isLoading ? 'Updating...' : 'Update'}
              disabled={isLoading}
            />
          </CardRow>
        </form>
      </Card>
      <ToastNotification
        open={showAvatarSuccessToast}
        title={'Avatar updated successfully'}
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M18.9946 19.1467C18.893 17.3926 17.3672 16 15.5 16H8.5C6.63276 16 5.10703 17.3926 5.00539 19.1467M18.9946 19.1467C20.8491 17.3314 22 14.8001 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.8001 3.15087 17.3314 5.00539 19.1467M18.9946 19.1467C17.1913 20.9119 14.7227 22 12 22C9.27726 22 6.80867 20.9119 5.00539 19.1467M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z'
              stroke='#ffffff'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      />
      <ToastNotification
        open={showAccountUpdateSuccessToast}
        title={'Account name updated successfully'}
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M18.9946 19.1467C18.893 17.3926 17.3672 16 15.5 16H8.5C6.63276 16 5.10703 17.3926 5.00539 19.1467M18.9946 19.1467C20.8491 17.3314 22 14.8001 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.8001 3.15087 17.3314 5.00539 19.1467M18.9946 19.1467C17.1913 20.9119 14.7227 22 12 22C9.27726 22 6.80867 20.9119 5.00539 19.1467M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z'
              stroke='#ffffff'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      />
    </PageLayout>
  );
}
