import React from 'react';
import clsx from 'clsx';

import styles from './ActionItem.module.css';

export function ActionItem({ action }) {
  const { title, user_name, project_name, user_avatar_url, action_name } =
    action;
  const eventMap = {
    task_created: {
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='3'
            y='3'
            width='18'
            height='18'
            rx='6'
            stroke='#8b91a0'
            strokeWidth='2'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> created task <strong>{title}</strong> in{' '}
          <strong>{project_name}</strong>
        </>
      ),
    },
    task_done: {
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.5 12.5124L10.8412 14.851C11.9672 12.8821 13.5256 11.1944 15.3987 9.91536L15.5 9.84619M12 21C9.20435 21 7.80653 21 6.7039 20.5433C5.23373 19.9343 4.06569 18.7663 3.45672 17.2961C3 16.1935 3 14.7956 3 12C3 9.20435 3 7.80653 3.45672 6.7039C4.06569 5.23373 5.23373 4.06569 6.7039 3.45672C7.80653 3 9.20435 3 12 3C14.7956 3 16.1935 3 17.2961 3.45672C18.7663 4.06569 19.9343 5.23373 20.5433 6.7039C21 7.80653 21 9.20435 21 12C21 14.7956 21 16.1935 20.5433 17.2961C19.9343 18.7663 18.7663 19.9343 17.2961 20.5433C16.1935 21 14.7956 21 12 21Z'
            stroke='#8b91a0'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> marked task <strong>{title}</strong> as{' '}
          <strong>done</strong> in <strong>{project_name}</strong>
        </>
      ),
    },
    task_todo: {
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='3'
            y='3'
            width='18'
            height='18'
            rx='6'
            stroke='#8b91a0'
            strokeWidth='2'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> marked task <strong>{title}</strong> as{' '}
          <strong>to-do</strong> in <strong>{project_name}</strong>
        </>
      ),
    },
    task_deleted: {
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='3'
            y='3'
            width='18'
            height='18'
            rx='6'
            stroke='#8b91a0'
            strokeWidth='2'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> deleted task <strong>{title}</strong> in{' '}
          <strong>{project_name}</strong>
        </>
      ),
    },
    event_created: {
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8 2V4.12777M8 6V4.12777M16 2V4.12777M16 6V4.12777M20.9597 10C21 10.7878 21 11.7554 21 13C21 15.7956 21 17.1935 20.5433 18.2961C19.9343 19.7663 18.7663 20.9343 17.2961 21.5433C16.1935 22 14.7956 22 12 22C9.20435 22 7.80653 22 6.7039 21.5433C5.23373 20.9343 4.06569 19.7663 3.45672 18.2961C3 17.1935 3 15.7956 3 13C3 11.7554 3 10.7878 3.0403 10M20.9597 10C20.9095 9.01824 20.7967 8.31564 20.5433 7.7039C19.9343 6.23373 18.7663 5.06569 17.2961 4.45672C16.9146 4.29871 16.4978 4.19536 16 4.12777M20.9597 10H3.0403M3.0403 10C3.09052 9.01824 3.20333 8.31564 3.45672 7.7039C4.06569 6.23373 5.23373 5.06569 6.7039 4.45672C7.08538 4.29871 7.50219 4.19536 8 4.12777M8 4.12777C8.94106 4 10.1716 4 12 4C13.8284 4 15.0589 4 16 4.12777M7 14H8V15H7V14Z'
            stroke='#8b91a0'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> created event <strong>{title}</strong> in{' '}
          <strong>{project_name}</strong>
        </>
      ),
    },
    event_deleted: {
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8 2V4.12777M8 6V4.12777M16 2V4.12777M16 6V4.12777M20.9597 10C21 10.7878 21 11.7554 21 13C21 15.7956 21 17.1935 20.5433 18.2961C19.9343 19.7663 18.7663 20.9343 17.2961 21.5433C16.1935 22 14.7956 22 12 22C9.20435 22 7.80653 22 6.7039 21.5433C5.23373 20.9343 4.06569 19.7663 3.45672 18.2961C3 17.1935 3 15.7956 3 13C3 11.7554 3 10.7878 3.0403 10M20.9597 10C20.9095 9.01824 20.7967 8.31564 20.5433 7.7039C19.9343 6.23373 18.7663 5.06569 17.2961 4.45672C16.9146 4.29871 16.4978 4.19536 16 4.12777M20.9597 10H3.0403M3.0403 10C3.09052 9.01824 3.20333 8.31564 3.45672 7.7039C4.06569 6.23373 5.23373 5.06569 6.7039 4.45672C7.08538 4.29871 7.50219 4.19536 8 4.12777M8 4.12777C8.94106 4 10.1716 4 12 4C13.8284 4 15.0589 4 16 4.12777M7 14H8V15H7V14Z'
            stroke='#8b91a0'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> deleted event <strong>{title}</strong> in{' '}
          <strong>{project_name}</strong>
        </>
      ),
    },
    post_created: {
      icon: (
        <svg
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.5 10H16.5M8.5 14H12.5M21.5 12C21.5 16.9706 17.4706 21 12.5 21C12.0683 21 11.6437 20.9696 11.2283 20.9108C10.0246 20.7406 9.42273 20.6555 9.26429 20.6433C9.0069 20.6234 9.09919 20.6266 8.84105 20.6286C8.68217 20.6298 8.50106 20.6428 8.1393 20.6686L5.98597 20.8224C5.12856 20.8837 4.69982 20.9143 4.37922 20.7623C4.09778 20.6289 3.87113 20.4022 3.7377 20.1208C3.5857 19.8002 3.61632 19.3715 3.67757 18.514L3.83138 16.3607C3.85723 15.9988 3.87015 15.8179 3.87139 15.6589C3.87339 15.4008 3.87659 15.4931 3.85674 15.2357C3.84452 15.0773 3.7594 14.4754 3.58915 13.2717C3.53039 12.8563 3.5 12.4317 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z'
            stroke='#8b91a0'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> created post <strong>{title}</strong> in{' '}
          <strong>{project_name}</strong>
        </>
      ),
    },
    post_deleted: {
      icon: (
        <svg
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.5 10H16.5M8.5 14H12.5M21.5 12C21.5 16.9706 17.4706 21 12.5 21C12.0683 21 11.6437 20.9696 11.2283 20.9108C10.0246 20.7406 9.42273 20.6555 9.26429 20.6433C9.0069 20.6234 9.09919 20.6266 8.84105 20.6286C8.68217 20.6298 8.50106 20.6428 8.1393 20.6686L5.98597 20.8224C5.12856 20.8837 4.69982 20.9143 4.37922 20.7623C4.09778 20.6289 3.87113 20.4022 3.7377 20.1208C3.5857 19.8002 3.61632 19.3715 3.67757 18.514L3.83138 16.3607C3.85723 15.9988 3.87015 15.8179 3.87139 15.6589C3.87339 15.4008 3.87659 15.4931 3.85674 15.2357C3.84452 15.0773 3.7594 14.4754 3.58915 13.2717C3.53039 12.8563 3.5 12.4317 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z'
            stroke='#8b91a0'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> deleted post <strong>{title}</strong> in{' '}
          <strong>{project_name}</strong>
        </>
      ),
    },
    project_file_created: {
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19.4748 12.781L15.9146 18.9474C14.2292 21.8665 10.4966 22.8667 7.57751 21.1813C4.65841 19.496 3.65825 15.7633 5.34359 12.8442L10.4296 4.03507C11.5531 2.089 14.0416 1.42223 15.9876 2.54579C17.9337 3.66935 18.6005 6.15778 17.4769 8.10385L12.3909 16.913C11.8291 17.886 10.5849 18.2194 9.6119 17.6576C8.63886 17.0959 8.30548 15.8517 8.86726 14.8786L13.4446 6.95037'
            stroke='#8b91a0'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> added file <strong>{title}</strong> in{' '}
          <strong>{project_name}</strong>
        </>
      ),
    },
    project_file_deleted: {
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19.4748 12.781L15.9146 18.9474C14.2292 21.8665 10.4966 22.8667 7.57751 21.1813C4.65841 19.496 3.65825 15.7633 5.34359 12.8442L10.4296 4.03507C11.5531 2.089 14.0416 1.42223 15.9876 2.54579C17.9337 3.66935 18.6005 6.15778 17.4769 8.10385L12.3909 16.913C11.8291 17.886 10.5849 18.2194 9.6119 17.6576C8.63886 17.0959 8.30548 15.8517 8.86726 14.8786L13.4446 6.95037'
            stroke='#8b91a0'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      copy: (
        <>
          <strong>{user_name}</strong> deleted file <strong>{title}</strong> in{' '}
          <strong>{project_name}</strong>
        </>
      ),
    },
  };

  return (
    <div className={clsx(styles.base, 'py-3 px-6')}>
      <div className='flex items-start'>
        <div
          className={clsx(
            styles['type-wrapper'],
            'mr-4 flex items-center justify-center'
          )}
        >
          {eventMap[action.action_name]['icon']}
        </div>
        <div className='flex items-start mt-2'>
          <div
            className={clsx(styles.avatar, 'mr-2')}
            style={{ backgroundImage: `url(${action.user_avatar_url})` }}
          ></div>
          <p>{eventMap[action.action_name]['copy']}</p>
        </div>
      </div>
    </div>
  );
}
