import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import {
  Label,
  Card,
  CardRow,
  DropdownMenu,
  PageLayout,
  Header,
  TrashIcon,
  PencilIcon,
  Link,
} from '../../../ui';

import { DropboxLogo } from '../../../shared/logos/Dropbox';
import { FigmaLogo } from '../../../shared/logos/Figma';
import { NotionLogo } from '../../../shared/logos/Notion';
import { GoogleDocsLogo } from '../../../shared/logos/GoogleDocs';
import { GoogleDriveLogo } from '../../../shared/logos/GoogleDrive';
import { GoogleSheetsLogo } from '../../../shared/logos/GoogleSheets';
import { GoogleSlidesLogo } from '../../../shared/logos/GoogleSlides';
import { MicrosoftOneDriveLogo } from '../../../shared/logos/MicrosoftOneDrive';
import { FileDialog } from '../_shared/components/FileDialog';
import { Comments } from '../../../features';

import { useRequest, useRouter } from '../../../hooks';

import styles from './FilesShow.module.css';

export function FilesShow() {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const { request, data } = useRequest();
  const ROUTER = useRouter();

  const fetchFile = () => {
    request({
      url: ROUTER.API.FILES_SHOW,
      onSuccess: () => {},
      onError: () => {},
    });
  };

  useEffect(() => {
    fetchFile();
  }, []);

  const logoMap = {
    ['Dropbox']: <DropboxLogo />,
    ['Figma']: <FigmaLogo />,
    ['Google Docs']: <GoogleDocsLogo />,
    ['Google Drive']: <GoogleDriveLogo />,
    ['Google Sheets']: <GoogleSheetsLogo />,
    ['Google Slides']: <GoogleSlidesLogo />,
    ['Microsoft OneDrive']: <MicrosoftOneDriveLogo />,
    ['Notion']: <NotionLogo />,
    ['Other']: (
      <svg
        width='18'
        height='22'
        viewBox='0 0 18 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17 10C17 8.34315 15.6569 7 14 7H13.4C13.0284 7 12.8426 7 12.6871 6.97538C11.8313 6.83983 11.1602 6.16865 11.0246 5.31287C11 5.1574 11 4.9716 11 4.6V4C11 2.34315 9.6569 1 8 1M17 9V17C17 19.2091 15.2091 21 13 21H5C2.79086 21 1 19.2091 1 17V5C1 2.79086 2.79086 1 5 1H9C13.4183 1 17 4.58172 17 9Z'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  };

  return (
    <PageLayout>
      <Header>
        <FileDialog
          open={showEditDialog}
          onOpenChange={setShowEditDialog}
          file={data}
          handleRefetch={fetchFile}
          isEditing
        />
        <h1>{data?.customName}</h1>
        <DropdownMenu
          items={[
            {
              text: 'Edit',
              icon: <PencilIcon />,
              onClick: () => {
                setShowEditDialog(true);
              },
            },
            {
              text: 'Delete',
              icon: <TrashIcon />,
              onClick: () => {
                request({
                  method: 'delete',
                  url: ROUTER.API.FILES_DELETE,
                  onSuccess: () => {
                    ROUTER.NAVIGATE(ROUTER.PATH.FILES_INDEX);
                  },
                  onError: () => {},
                });
              },
              danger: true,
            },
          ]}
        />
      </Header>
      <div className={clsx(styles.file)}>
        <div className={clsx(styles['service-icon'])}>
          {logoMap[data?.service_name]}
        </div>
        <h2>{data?.custom_name}</h2>
        <Link href={data?.url} target='_blank'>
          <svg
            width='19'
            height='18'
            viewBox='0 0 19 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={clsx(styles['link-icon'])}
          >
            <path
              d='M17.5 10.5001C17.5 11.8946 17.5 12.5919 17.3618 13.1673C16.9229 14.9956 15.4955 16.423 13.6672 16.8619C13.0918 17.0001 12.3945 17.0001 11 17.0001H9.5C6.69974 17.0001 5.29961 17.0001 4.23005 16.4551C3.28924 15.9758 2.52433 15.2109 2.04497 14.2701C1.5 13.2005 1.5 11.8004 1.5 9.0001V8.5001C1.5 6.17035 1.5 5.0055 1.8806 4.08664C2.38807 2.8615 3.36144 1.88813 4.58658 1.38066C5.36344 1.05888 6.31614 1.00915 8 1.00146M17.2597 6.45455C17.5221 4.8217 17.5697 3.16984 17.4019 1.54138C17.3898 1.42328 17.338 1.31854 17.2597 1.24027M17.2597 1.24027C17.1815 1.16201 17.0767 1.11023 16.9586 1.09806C15.3302 0.930252 13.6783 0.977922 12.0455 1.24027M17.2597 1.24027L7.5 11'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          View on {data?.service_name}
        </Link>
      </div>
      <Card>
        <CardRow key={data?.unique_id} className={clsx(styles.base)}>
          <Label label='Notes' />
          <div dangerouslySetInnerHTML={{ __html: data?.notes }} />
        </CardRow>
      </Card>
      <Comments
        parent={{
          parent_type: 'ProjectFile',
          parent_id: data?.unique_id,
        }}
      />
    </PageLayout>
  );
}
