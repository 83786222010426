import React from 'react';
import clsx from 'clsx';

import styles from './Header.module.css';

export function Header({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={clsx(styles.base, 'my-12', className)}>{children}</div>
  );
}
