import React, { useState, useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AccountContext } from '../../../../contexts/account';

import clsx from 'clsx';

import { Button } from '../../../../ui';
import { Editor } from '../../../Editor/Editor';

import styles from '../../Comments.module.css';

export function NewComment({
  sharedEndpoint,
}: {
  sharedEndpoint: string;
  parent: { comment_type: string; comment_id: string };
}) {
  const [content, setContent] = useState('');
  const [resetContent, setResetContent] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const account = useContext(AccountContext);

  const queryClient = useQueryClient();

  const createComment = useMutation({
    mutationFn: () => {
      return fetch(sharedEndpoint, {
        method: 'post',
        body: JSON.stringify({
          body: content,
          attachments,
        }),
        headers: {
          'X-CSRF-Token': document?.querySelector('meta[name=csrf-token]')
            ?.content,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['fetchComments'],
      });
      setContent('');
      setResetContent(true);
      setTimeout(() => {
        setResetContent(false);
      }, 100);
    },
  });

  return (
    <div className={clsx(styles['comment-row'])}>
      <div className={clsx(styles.wrapper)}>
        <div className={clsx(styles['avatar-wrapper'])}>
          <div
            className={clsx(styles.avatar)}
            style={{ backgroundImage: `url(${account?.avatar_url})` }}
          />
        </div>
        {!resetContent && (
          <Editor
            initialAttachments={[]}
            setContent={setContent}
            getAttachments={setAttachments}
          />
        )}
      </div>
      <div className={clsx(styles['actions-wrapper'])}>
        <Button label='Add comment' onClick={() => createComment.mutate()} />
      </div>
    </div>
  );
}
